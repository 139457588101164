<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<h5>Charting</h5>
				<p>Here you can chart any available security, create comparisons, add indicators and more...</p>
				<!-- <LogoSpinner /> -->
				<AdvChart :selectedSymbol="selectedSymbol" :allowSymbolChange="true"/>
			</div>
		</div>
	</div>
</template>

<script>
import AdvChart from '../components/TradingView/AdvChart';
//import LogoSpinner from '../components/common/LogoSpinner';
export default {
	components: {
		AdvChart,
		//LogoSpinner,
	},
	computed : {
		selectedSymbol() {
			if( this.$store.state.selectedChartSymbol ) {
				return  this.$store.state.selectedChartSymbol;
			}
			else {
				return "Change Me";
			}
		}
	}
}
</script>

<style scoped>
</style>
<template>
  <!-- TradingView Widget BEGIN -->
  <LoadingSpinner :showLoading="!chartLoaded" minHeight="590px;" />
  
 <Button v-show="chartLoaded" icon="pi pi-camera" @click="callImage" class="p-button-text p-button-lg" style="height: 40px; float:right;margin-bottom:-40px;margin-right:10px;z-index:12;"/>
      
    
<div class="tradingview-widget-container" id="tv-wrapper-container">

    
    <div id="advTvChart"  v-show="chartLoaded">

    </div>
    <div class="tradingview-widget-copyright" v-show="chartLoaded">
        <a href="https://www.tradingview.com/symbols/{{selectedSymbol}}" rel="noopener" target="_blank">
        <span class="blue-text">{{selectedSymbol}}</span></a> by TradingView
    </div>
    
    
</div>
<Dialog header="Your Chart" v-model:visible="displaySnapshot" :style="{width: '1000px', height: 'auto'}">
         <LoadingSpinner :showLoading="loadingImage" text="Creating Snapshot..." textPosition="margin-top:195px;margin-left:420px;" minHeight="350px;" />
        <img :src="snapshotUrl" :style="{width: '950px', height: 'auto'}" :onload="imgLoaded"/>
        <template #footer>
		<!-- <Button label="Download" icon="pi pi-cloud-download" class="p-button-text" @click="downloadChart"/>
        <Button label="Tweet" icon="pi pi-twitter" class="p-button-text" @click="tweet"/>
        <Button label="Share" icon="pi pi-facebook" class="p-button-text" @click="shareOnFacebook"/>
        <Button label="Close" icon="pi pi-times" class="p-button-text"  @click="displaySnapshot=false"/> -->
        <Menubar :model="items" style="border:none;">
            <template #start>
                
                
            </template>
            <template #end>
                 <a class="p-menuitem-link" aria-haspopup="false" aria-expanded="false" role="menuitem" tabindex="0"  @click="displaySnapshot=false" style="float:right;">
                     <span class="p-menuitem-icon pi pi-times"></span><span class="p-menuitem-text">Close</span>
                </a>
            </template>
        </Menubar>
	</template> 

    </Dialog>
<!-- TradingView Widget END -->
</template>

<script>
import {mapState} from 'vuex';
import {APP_PROTOCOL} from "@/common/config";
import LoadingSpinner from '../common/LoadingSpinner';
import Dialog from 'primevue/dialog';
import ResearchService from '../../service/ResearchService';
import axios from 'axios';
import Menubar from 'primevue/menubar';

export default {
    name: "AdvChart",
    props: {
        selectedSymbol: {
            type: String,
            required: false,
            default: 'GOOG',
        },
        allowSymbolChange: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        LoadingSpinner,
        Dialog,
        Menubar
    },
     data() {
        return {
            chartLoaded: false,
            justedMounted: false,
            currentSymbol: null,
           
            active: false,
            chart: null,
            displaySnapshot: false,
            snapshotUrl: null,
            spinnerStyle: "z-index: 11",
            loadingImage: false,
            items: [
                {
                    label: 'Share',
                    icon: 'pi pi-share-alt',
                    items: [
                        {
                            label: 'On Discord',
                            icon: 'pi pi-discord',
                            command: () => {
                                this.mention();
                            }
                        },
                        {
                            label: 'On Twitter',
                            icon: 'pi pi-twitter',
                            command: () => {
                                this.tweet();
                            }
                        },
                        {
                            label: 'On Facebook',
                            icon: 'pi pi-facebook',
                            command: () => {
                                this.shareOnFacebook();
                            }
                        },
                    ]
                },
                {
                    label: 'Download',
                    icon: 'pi pi-download',
                    command: () => {
                        this.download();
                    }
                },
                

            ]
           
        }
    },
    computed : {
        ...mapState(['selectedEquity']),
    },
    mounted() {
        this.currentSymbol = this.$props.selectedSymbol;

       this.$nextTick( () => {
        
          this.createChart();
            
            // console.debug(JSON.stringify(this.widget));
       });


     
      
       this.justedMounted = true;
        
    }, 
    watch: {
		//selectedEquity() {
       //     console.debug("selected equity adv chart watcher called");
            //if( !this.justedMounted && this.active && this.$route.path =='/equity/charting') {
             //   this.updateSymbolIfRequired();
            //}
		
		//},
         $route(newRoute, oldRoute) { //eslint-disable-line
             
            if( newRoute.fullPath =='/equity/charting') {
               
                this.updateSymbolIfRequired();
            }
        },
	},
    activated() {
        console.debug("adv chart activated");
        this.active = true;
        if( !this.justedMounted ) {
            this.updateSymbolIfRequired();
        }
        this.justedMounted = false;
        
       
    },
    deactivated() {
        this.active = false;
        this.justedMounted = false;
    },
    methods: {
        callImage() {
            console.debug("call Image called");
            this.snapshotUrl = null;
            this.displaySnapshot = true;
            this.loadingImage= true;
            this.chart.image((e) => { this.showChartImage( e )});
        },
        showChartImage( url) {
            console.debug("show chart image called with "+ url);
            
            let userId = this.$store.state.users.user.userId;
            ResearchService.getInvrsChart(userId, url).then(response => {
				//console.debug(response.data+APP_PROTOCOL)
                
				this.snapshotUrl = APP_PROTOCOL+response.data.invrsUrl;
                
               
          })
          .catch(error => {
              
            console.debug('There was an error:', error.response)
          })
        },

        imgLoaded() {
            console.debug("image loaded");
             this.loadingImage= false;
        },
         downloadChart() {
           
            this.chart.getSymbolInfo((symbol) => {
                 //console.debug(JSON.stringify(e));
                
                 //var e = document.createElement('a');
                var href =  this.snapshotUrl;
                this.downloadWithAxios(href,symbol.name+"-chart.png");
                // console.debug("href set to " + href);
                // e.setAttribute('href', href+"?download");
                // e.setAttribute('download', symbol.name+"-chart.png");
                // document.body.appendChild(e);
                // e.click();
                // document.body.removeChild(e);
               // this.displaySnapshot = false;
            });
           
                
           
         },

        forceFileDownload(response, title) {
            console.debug(title)
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', title)
            document.body.appendChild(link)
            link.click()
             //this.displaySnapshot = false;
        },
        downloadWithAxios(url, title) {
            axios({
                method: 'get',
                url,
                responseType: 'arraybuffer',
            })
                .then((response) => {
                this.forceFileDownload(response, title)
                })
                .catch(() => console.debug('error occured'))
        },
        mention() {

        },
         tweet() {
             this.chart.getSymbolInfo((symbol) => {
            
               let url = 'https://twitter.com/intent/tweet?&text=%23'+symbol.name+' chart '+this.snapshotUrl+' created on https://www.invrs.com';
               window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');

            });
         },
         shareOnFacebook() {
           
             this.chart.getSymbolInfo((symbol) => {
                let url = "http://www.facebook.com/sharer.php?u="+this.snapshotUrl+"&[title]="+symbol;
          
               window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');

            });
         },


        createChart() {
            this.chart = new TradingView.widget( //eslint-disable-line
                    {
                    "width": "100%",
                    "height": "50em",
                    "symbol": this.selectedSymbol,
                    "interval": "D",
                    "timezone": "America/New_York",
                     "theme": "light",
                    "style": "3",
                    "locale": "en",
                    // "toolbar_bg": "#17212f",
                    "enable_publishing": false,
                    "withdateranges": true,
                    "hide_top_toolbar": false,
                    "hide_side_toolbar": false,
                    "allow_symbol_change": this.$props.allowSymbolChange,
                    "save_image": false,
                    "container_id": "advTvChart"
                    }
            );   
            if( this.selectedSymbol == 'Change Me') {
                setTimeout(() => { this.chartLoaded = true; }, 2000);
            }
            else {
                 this.chart.ready( () => { this.chartLoaded = true; });
            }
        },
        updateSymbolIfRequired() {
            if( !this.justMounted ) {
                let newSymbol = this.selectedSymbolForTv();
                if( this.currentSymbol != newSymbol ){
                    this.updateSymbol(newSymbol);
                }
            }
        },
        updateSymbol(newSymbol) {
           
            //let iframe = document.getElementById(this.chart.id);
            //let url = iframe.getAttribute("src");
            //this.chartLoaded = false;
            
            //iframe.setAttribute("src", url.replace(this.currentSymbol.replace(":", "%3A"), newSymbol.replace(":", "%3A")));
           // setTimeout(() => { this.chartLoaded = true; window.dispatchEvent(new Event('resize'));}, 2000);
           this.chartLoaded = false;
           this.createChart();
            this.currentSymbol = newSymbol;
        },

        selectedSymbolForTv() {
			let exchangeSymbol = this.$store.state.selectedEquity.exchangeSymbol;
			let symbol = this.$store.state.selectedEquity.symbol;
			if( exchangeSymbol == 'XNAS') {
				return "NASDAQ:" + symbol
			}
			else if( exchangeSymbol == 'XNYS') {
				return "NYSE:" + symbol
			}
			else if( exchangeSymbol == 'OOTC') {
				return "OTC:" + symbol
			}
			else if( exchangeSymbol == 'XTSE') {
				return "TSX:" + symbol
			}
			else if( exchangeSymbol == 'XTSX') {
				return "TSXV:" + symbol
			}
			else if( exchangeSymbol == 'XCNQ') {
				return "CSE:" + symbol
			}
			else {
				return symbol;
			}
			
		},
    }
    
}
</script>

<style scoped>
    /* ::v-deep(#advTvChart > div ){
        background-color:#1f2d40 !important;
    }
    ::v-deep(#advTvChart > div > div){
        background:#1f2d40 !important;
    } */
</style>
